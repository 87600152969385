<template>
    <OInputMask ref="inputRef"
        :modelValue="value"
        :mask="mask"
        :slotChar="format"
        :skipUpdates="skipUpdates"
        @focus="onFocus"
        @blur="onBlur"
        @paste="onPaste"
        @keypress="onKeyPress"
        @keydown.enter.capture="onEnter"
        @keydown="onKeyDown"
        spellcheck="false"/>
</template>

<script setup>
import OInputMask from 'o365.vue.components.InputMask.vue';
//import { format as formatDate } from 'o365.lib.date-fns.js';
import { format as formatDate, parse as parseDate  } from 'date-fns';
import { ref } from 'vue';

const props = defineProps({
    value: null,
    onInput: Function,
    onEnter: Function,
    onTab: Function,
    onClear: Function,
    format: String
});

const skipUpdates = ref(false);
const inputRef = ref(null);

let mask = props.format.replace(/[dDmMyYhHsS]/g, '9');

function formatToBuffer(format) {
    const dateChars = ['d', 'D', 'm', 'M', 'y', 'Y', 's', 'h', 'H', 'a'];
    let newGroup = true;
    const buffer = format.split('').reduce((arr, char, index) => {
        const isToken = dateChars.includes(char);
        if (isToken) {
            if (newGroup) {
                arr.push([char]);
                newGroup = false;
            } else {
                if (index > 0 && arr[arr.length-1] && arr[arr.length-1][0] === char) {
                    arr[arr.length-1].push(char);
                } else {
                    arr.push([char]);
                }
            }
        } else {
            if (typeof arr[arr.length-1] === 'number' ) {
                arr[arr.length-1] += 1;
            } else {
                arr.push(1);
            }
            newGroup = true;
        }

        return arr;
    }, []);

    const formatMap = [];
    const bufferMap = [];
    buffer.forEach((dateFormat, index) => {
        if (Array.isArray(dateFormat)) {
            const incitement = bufferMap.length;
            const formatRange = {
                format: dateFormat.join(''),
                range: [incitement, incitement+dateFormat.length]
            }
            formatMap.push(formatRange);
            dateFormat.forEach(() => {bufferMap.push(formatMap.length-1)});
        } else {
            for (let i = 0; i < dateFormat; i++) {
                bufferMap.push(null);
            }
        }
    });

    return [bufferMap, formatMap];
}

const [buffer, formatRamges] = formatToBuffer(props.format);

function replaceRangeInString(original, value, start, end) {
    const newValue = original.substring(0, start) + value + original.substring(end); 
    return newValue;
}

function passValue(e) {
    const cleanedValue = e.target.value.slice(0, e.target.selectionEnd);
    const fakeEvent = { target: { value: cleanedValue } };
    props.onInput(fakeEvent);
}

let isSuggesting = false;
let skipKeyPress = false;
function onKeyPress(e) {
    passValue(e);
    if (skipKeyPress) { skipKeyPress = false; return; } 
    if (isSuggesting) { return; }
    const currentPos = e.target.selectionEnd;
    const currentSlotPos = buffer[currentPos];
    if (!currentSlotPos) { return; }
    const currentSlot = formatRamges[currentSlotPos]
    if (currentPos > currentSlot.range[0]) { return; }

    const suggestedValue = formatDate(new Date(), currentSlot.format);

    isSuggesting = true;
    e.target.value = replaceRangeInString(e.target.value, suggestedValue, currentSlot.range[0], currentSlot.range[1]);
    e.target.setSelectionRange(currentSlot.range[0], currentSlot.range[1]);

    // Update input mask inner buffer
    inputRef.value.checkVal(true);
}
function onPaste(e){
   
    e.stopImmediatePropagation();
    e.preventDefault();
  
    let suggestedValue = e.clipboardData.getData("text");
    try{
        if(props.format && props.format.indexOf(".") > -1){
            suggestedValue = formatDate(parseDate(e.clipboardData.getData("text"),props.format,new Date()),props.format);
        }else{
            suggestedValue = formatDate(new Date(e.clipboardData.getData("text")), props.format);
        }
        


        e.target.value = suggestedValue;     
    }catch{
        e.target.value = suggestedValue;
    }
    passValue(e);
        inputRef.value.checkVal(true);
   
}

function onEnter(e) {
    e.stopImmediatePropagation();
    e.preventDefault();
    if (isSuggesting) {
        onKeyDown(e, true);
    }
    const currentPos = e.target.selectionEnd;
   
    e.target.value = e.target.value.slice(0, currentPos);

    props.onEnter(e);
     
   e.target.blur();
}

function onKeyDown(e, forceSet = false) {
    if (isSuggesting) {
        isSuggesting = false;
        if (e.key === 'Tab' || forceSet) {
            e.stopImmediatePropagation();
            e.preventDefault();
            const currentPos = e.target.selectionEnd;
            let nextPos;
            for (let i = currentPos; i <= buffer.length; i++) {
                nextPos = i;
                if (buffer[i] != null) { break; }
            }
            e.target.setSelectionRange(nextPos, nextPos);
            inputRef.value.checkVal(true);
            onKeyPress(e);
        } else {
            skipKeyPress = true;
        }
    } else {
        if (e.key === 'Tab') {
            props.onTab(e);
        }
    }
}

function onFocus(e) {
    skipUpdates.value = true;
    /*
    if (!props.value) {
        window.setTimeout(() => {
            const formatSlot = formatRamges[0];
            const suggestedValue = formatDate(new Date(), formatSlot.format);

            isSuggesting = true;
            e.target.value = replaceRangeInString(e.target.value, suggestedValue, formatSlot.range[0], formatSlot.range[1]);
            e.target.setSelectionRange(formatSlot.range[0], formatSlot.range[1]);

            // Update input mask inner buffer
            inputRef.value.checkVal(true);
        }, 10);
    }
    */
}

function onBlur(e) {
    skipUpdates.value = false;
}

</script>